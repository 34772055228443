import { PrimaryButton, TextButton, OutlinedButton } from '@dfds-pax/ui'
import { MenuItem } from './MenuItem'
import { Content, SlugItem } from '../Utils/content'

interface Props {
  disabled: boolean
  slugs: SlugItem[]
  content: Content
  onLogoutClicked: () => void
  onLoginClicked: () => void
  onCreateAccountClicked: () => void
}

const getMenuText = (slugItem: SlugItem, content: any) => {
  if (slugItem.id === '4SQzhVFYxYPQkcy3urgjFi') return content.MENU_ONE_TITLE
  if (slugItem.id === '5KCuKuA1SD3nEZyStlxP9u') return content.MENU_TWO_TITLE
  if (slugItem.id === '5P3CeDTyiA7ugyp8Uy1QvM') return content.MENU_THREE_TITLE
  return 'NOT FOUND'
}

export const Menu: React.FC<Props> = ({ disabled, slugs, content, onLogoutClicked, onLoginClicked, onCreateAccountClicked }) => {
  return (
    <div
      id="login-menu"
      className="al-bg-dfds-surface-light al-z-overlay mobile:al-fixed md:al-absolute al-top-[50px] mobile:al-left-4 al-right-4 md:al-min-w-[350px] al-shadow-xl al-rounded-lg"
    >
      <div className="al-leading-[28px] al-bg-dfds-primary-primary al-flex al-items-center al-p-dfds-s al-border-0 al-border-b al-border-solid al-border-dfds-divider-dark al-rounded-t-lg">
        <div className="al-dfds-small-headline al-text-dfds-text-light-primary">{content?.MY_PROFILE}</div>
      </div>
      {slugs?.map((slug, i) => (
        <MenuItem
          dataE2eAttr={`MENU-${i === 0 ? 'ONE' : i === 1 ? 'TWO' : 'THREE'}-TITLE`}
          key={slug.id}
          disabled={disabled}
          id={slug.id}
          slug={slug.slug}
          title={getMenuText(slug, content)}
        />
      ))}
      {disabled ? (
        <div className="al-flex al-flex-col al-items-center al-px-dfds-s al-pt-dfds-s al-rounded-b-lg">
          <div data-e2e-login className="al-w-full">
            <PrimaryButton className="ui-w-full" onClick={onLoginClicked}>
              {content?.LOGIN}
            </PrimaryButton>
          </div>
          <div className="al-w-full al-py-dfds-xs al-flex al-items-baseline al-gap-x-2 al-justify-start">
            <div className="al-dfds-label al-text-dfds-text-dark-primary">{content?.FIRST_TIME_HERE}</div>
            <TextButton onClick={onCreateAccountClicked}>{content?.CREATE_NEW_ACCOUNT}</TextButton>
          </div>
        </div>
      ) : (
        <div data-e2e-logout className="al-flex al-items-center al-px-dfds-s al-rounded-b-lg al-p-dfds-s">
          <OutlinedButton className="ui-w-full" onClick={onLogoutClicked}>
            {content?.LOGOUT}
          </OutlinedButton>
        </div>
      )}
    </div>
  )
}
