export type LabelProps = {
  label: string
  id?: string
  required: boolean
  disabled?: boolean
}

export const Label: React.FC<LabelProps> = ({ id, label, required, disabled }) => {
  return (
    <div className="ui-h-[20px] ui-flex ui-items-center ui-my-1">
      <label
        htmlFor={id}
        className={`truncate md:ui-dfds-label ui-text-dfds-text-primary-primary ${
          disabled ? 'ui-text-dfds-text-primary-disabled' : 'ui-text-dfds-text-primary-primary'
        }`}
      >
        {label}
      </label>
      {required && <span className="ui-dfds-label-bold ui-ml-[2px] ui-text-dfds-secondary-main">*</span>}
    </div>
  )
}

export default Label
