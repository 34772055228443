'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const Search = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M9.414 3c3.542 0 6.414 2.871 6.414 6.414 0 1.415-0.458 2.723-1.234 3.784l6.407 6.408-1.395 1.395-6.408-6.407c-1.061 0.776-2.369 1.234-3.784 1.234-3.542 0-6.414-2.871-6.414-6.414s2.871-6.414 6.414-6.414zM9.414 4.973c-2.452 0-4.44 1.988-4.44 4.44s1.988 4.44 4.44 4.44c2.452 0 4.44-1.988 4.44-4.44s-1.988-4.44-4.44-4.44z"
    ></path>
  </svg>
))
export default Search
