import { useEffect } from 'react'
import { useMsal, useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { getUrlLocale, msalAuthSettings } from '@dfds-pax/common'
import { Badge } from './Badge'

const Login: React.FC = () => {
  const { instance } = useMsal()
  const isLoggedIn: boolean = useIsAuthenticated()
  const hasValidToken = useMsalAuthentication(InteractionType.None)

  const locale = getUrlLocale()

  useEffect(() => {
    instance.handleRedirectPromise().then((tokenResponse) => {
      if (tokenResponse) {
        instance.setActiveAccount(tokenResponse.account)
      }
    })
  }, [instance])

  return (
    <Badge
      isLoggedIn={isLoggedIn && hasValidToken.result != null}
      email={
        (instance.getActiveAccount()?.idTokenClaims?.given_name as string) ?? (instance.getActiveAccount()?.idTokenClaims?.email as string)
      }
      onLoginClicked={async () =>
        await instance.loginRedirect({
          scopes: msalAuthSettings.scopes,
          extraQueryParameters: { ui_locales: locale },
        })
      }
      onCreateAccountClicked={async () =>
        await instance.loginRedirect({
          scopes: msalAuthSettings.scopes,
          extraQueryParameters: { flowState: 'signup', ui_locales: locale },
        })
      }
      onLogoutClicked={async () => {
        sessionStorage.removeItem('pax_Token')
        sessionStorage.removeItem('pax_Sub')
        document.cookie = `pax_AuthType=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`
        await instance.logout()
      }}
    />
  )
}

export default Login
