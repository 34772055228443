'use client'

import clsx from 'clsx'
import { Typograhy } from '../Typograhy'
import { ReactNode } from 'react'
import ExternalLink from '../Icons/ExternalLink'

export type OutlinedButtonProps = {
  children?: string | ReactNode
  className?: string
  dataAttr?: string
  external?: boolean
  onClick: () => void
}

export const OutlinedButton: React.FC<OutlinedButtonProps> = ({ className, external, children, onClick, dataAttr, ...rest }) => {
  return (
    <button
      data-e2e={dataAttr}
      title={String(children)}
      className={clsx(
        'ui-h-[44px] ui-flex ui-flex-row ui-items-center ui-justify-center ui-rounded-sm ui-cursor-pointer ui-px-dfds-s ui-border-solid ui-border-2 ui-bg-transparent ui-border-dfds-secondary-main ui-text-dfds-secondary-main hover:ui-outline-dfds-secondary-dark hover:ui-text-dfds-secondary-dark',
        className
      )}
      onClick={onClick}
      {...rest}
    >
      <Typograhy className="ui-text-ellipsis ui-whitespace-nowrap ui-overflow-hidden" as="labelBold">
        {children}
      </Typograhy>

      {external && (
        <div className="ui-ml-dfds-xs">
          <ExternalLink height={24} width={24} />
        </div>
      )}
    </button>
  )
}
