import { getContentfulLocale, getUrlLocale } from '@dfds-pax/common'
import { getClientEnvironment, logger } from '@dfds-pax/common'
import { LoggingInterfaces } from '@dfds-pax/logging-provider'
import { v4 as uuidv4 } from 'uuid'

interface Item {
  fields: {
    entryTitle: string
    value: string
  }
}
interface ContentfulSlugItem {
  sys: {
    id: string
  }
  fields: {
    slug: string
  }
}

export interface SlugItem {
  id: string
  slug: string
}

export interface Content {
  LOGOUT: string | undefined
  LOGIN: string | undefined
  MY_PROFILE: string | undefined
  CREATE_NEW_ACCOUNT: string | undefined
  FIRST_TIME_HERE: string | undefined
  MENU_ONE_TITLE: string | undefined
  MENU_TWO_TITLE: string | undefined
  MENU_THREE_TITLE: string | undefined
}

export const GetUrlSlugs = async (): Promise<SlugItem[]> => {
  const urlLocale = getUrlLocale()
  const contentfulLocale = getContentfulLocale(urlLocale)

  const urlSlugUrl = new URL('https://cdn.contentful.com/spaces/mivicpf5zews/environments/master/entries')
  urlSlugUrl.searchParams.append('content_type', 'urlSlug')
  urlSlugUrl.searchParams.append('locale', contentfulLocale)
  urlSlugUrl.searchParams.append('sys.id[in]', '4SQzhVFYxYPQkcy3urgjFi,5KCuKuA1SD3nEZyStlxP9u,5P3CeDTyiA7ugyp8Uy1QvM')
  urlSlugUrl.searchParams.append('limit', '100')

  const result = await httpClient(urlSlugUrl.toString(), {
    Authorization: 'Bearer ffb6d2e75bdeb11580a166b1856d1b183e180c6da95a97998ab50cd12ad9eb85',
  })

  return result.items.map((item: ContentfulSlugItem) => {
    const slugItem: SlugItem = {
      id: item.sys.id,
      slug: item.fields.slug,
    }
    return slugItem
  })
}

export const GetContent = async (): Promise<Content> => {
  const urlLocale = getUrlLocale()
  const contentfulLocale = getContentfulLocale(urlLocale)

  const contentUrl = new URL('https://cdn.contentful.com/spaces/z860498abl84/environments/master/entries')
  contentUrl.searchParams.append('content_type', 'paxObResource')
  contentUrl.searchParams.append('locale', contentfulLocale)
  contentUrl.searchParams.append('fields.applications', 'login')
  contentUrl.searchParams.append('limit', '100')

  const contentResult = await httpClient(contentUrl.toString(), {
    Authorization: 'Bearer cf3eb41c6603c064c84111798dd42d288c8e0262017997f894653da20797e665',
  })

  const result: Content = {
    LOGOUT: contentResult.items.find((item: Item) => item.fields.entryTitle === 'LOGOUT')?.fields?.value,
    LOGIN: contentResult.items.find((item: Item) => item.fields.entryTitle === 'LOGIN')?.fields?.value,
    MY_PROFILE: contentResult.items.find((item: Item) => item.fields.entryTitle === 'MY-PROFILE')?.fields?.value,
    CREATE_NEW_ACCOUNT: contentResult.items.find((item: Item) => item.fields.entryTitle === 'CREATE-NEW-ACCOUNT')?.fields?.value,
    FIRST_TIME_HERE: contentResult.items.find((item: Item) => item.fields.entryTitle === 'FIRST-TIME-HERE')?.fields?.value,
    MENU_ONE_TITLE: contentResult.items.find((item: Item) => item.fields.entryTitle === 'MENU-ONE-TITLE')?.fields?.value,
    MENU_TWO_TITLE: contentResult.items.find((item: Item) => item.fields.entryTitle === 'MENU-TWO-TITLE')?.fields?.value,
    MENU_THREE_TITLE: contentResult.items.find((item: Item) => item.fields.entryTitle === 'MENU-THREE-TITLE')?.fields?.value,
  }

  return result
}

async function httpClient(url: string, headers?: HeadersInit) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      ...headers,
    },
  }

  let response
  try {
    response = await fetch(url, requestOptions)
    if (!response.ok) {
      let responseObj
      let jsonError
      try {
        responseObj = await response.json()
      } catch (error) {
        jsonError = 'Could not parse response body as JSON'
      }
      const errorMessage =
        responseObj?.message || responseObj?.Message || responseObj?.title || jsonError || 'Message did not exist in error response.'
      const httpClientError = new Error(errorMessage)
      throw httpClientError
    }
    return await response.json()
  } catch (error: any) {
    let errorMessage = error?.message || 'Unknown fetch error'
    const payload: LoggingInterfaces.LogProps = {
      message: errorMessage,
      messageTemplate: 'Error getting content from contentful',
      level: 'Error',
      fields: {
        correlationId: uuidv4(),
        application: 'azure-login',
        environment: getClientEnvironment(),
        frontend: true,
        path: window.location.pathname,
        referer: document.referrer,
        userAgent: navigator.userAgent,
        error,
      },
    }
    logger('Error', getClientEnvironment(), payload)
  }
}
