import { getUrlLocale } from '@dfds-pax/common'

const getHref = (slug: string) => {
  return typeof window !== 'undefined' ? window.location.origin + '/' + getUrlLocale() + '/' + slug : ''
}

interface Props {
  id: string
  title: string
  slug: string
  disabled: boolean
  dataE2eAttr: string
}

export const MenuItem: React.FC<Props> = ({ id, title, slug, disabled, dataE2eAttr }) => {
  return (
    <a
      data-e2e-content-slug-id={id}
      data-e2e-content-slug={slug}
      href={getHref(slug)}
      data-e2e={dataE2eAttr}
      className={`
        al-leading-[20px] 
        al-p-dfds-s
        al-flex 
        al-items-center 
        al-border-0 
        al-border-b 
        al-border-solid 
        al-no-underline
        al-border-dfds-divider-dark 
        hover:al-bg-dfds-listitem-hover 
        ${disabled ? 'al-cursor-not-allowed al-pointer-events-none' : 'al-cursor-pointer'}
      `}
    >
      <div className={`al-dfds-body-interface ${disabled ? 'al-text-dfds-text-primary-disabled' : 'al-text-dfds-text-primary-primary'}`}>
        {title}
      </div>
    </a>
  )
}
