'use client'

import clsx from 'clsx'
import { Typograhy } from '../Typograhy'
import { ReactNode } from 'react'

export type TextButtonProps = {
  children?: string | ReactNode
  className?: string
  dataAttr?: string
  onClick: () => void
}

export const TextButton: React.FC<TextButtonProps> = ({ className, children, onClick, dataAttr, ...rest }) => {
  return (
    <button
      data-e2e={dataAttr}
      title={String(children)}
      className={clsx(
        'ui-bg-transparent ui-border-0 ui-cursor-pointer ui-text-dfds-text-secondary-primary ui-h-[44px] ui-rounded-sm hover:ui-opacity-90 active:ui-opacity-80',
        className
      )}
      onClick={onClick}
      {...rest}
    >
      <Typograhy className="ui-text-ellipsis ui-whitespace-nowrap ui-overflow-hidden" as="labelBold">
        {children}
      </Typograhy>
    </button>
  )
}
