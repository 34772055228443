import { useEffect, useRef, useState } from 'react'
import { Account } from '@dfds-pax/ui'
import { Menu } from './Menu'
import { Content, GetContent, GetUrlSlugs, SlugItem } from '../Utils/content'

interface Props {
  isLoggedIn: boolean
  email: string | undefined
  onLoginClicked: () => void
  onLogoutClicked: () => void
  onCreateAccountClicked: () => void
}

export const Badge: React.FC<Props> = ({ isLoggedIn, email, onLoginClicked, onLogoutClicked, onCreateAccountClicked }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const [content, setContent] = useState<Content>()
  const [urls, setUrls] = useState<SlugItem[]>()

  const badgeRef = useRef<any>(null)

  useEffect(() => {
    const handleClickOutsideSelf = (event: any) => {
      if (badgeRef.current && !badgeRef.current.contains(event.target)) setShowMenu(false)
    }
    addEventListener('mousedown', handleClickOutsideSelf)
    return () => removeEventListener('mousedown', handleClickOutsideSelf)
  }, [badgeRef])

  const handleShowMenu = async () => {
    if (!content) {
      const _content: Content = await GetContent()
      setContent(_content)
    }
    if (!urls) {
      const _urls: SlugItem[] = await GetUrlSlugs()
      const menuItemsOrder = ['4SQzhVFYxYPQkcy3urgjFi', '5KCuKuA1SD3nEZyStlxP9u', '5P3CeDTyiA7ugyp8Uy1QvM']
      const urlsSorted = _urls?.sort((a: SlugItem, b: SlugItem) => menuItemsOrder.indexOf(a.id) - menuItemsOrder.indexOf(b.id))
      setUrls(urlsSorted)
    }
    setShowMenu(!showMenu)
  }

  return (
    <div className="al-relative al-flex al-select-none">
      <div ref={badgeRef}>
        <div
          id="login-badge"
          data-e2e-loggedin={isLoggedIn}
          onClick={handleShowMenu}
          className={`al-ml-auto al-text-dfds-primary-main al-flex al-items-center al-cursor-pointer al-justify-center hover:al-bg-dfds-listitem-hover hover:al-border-dfds-secondary-main hover:al-border hover:al-border-solid al-h-[44px] al-w-[48px]`}
        >
          {!isLoggedIn ? (
            <Account height={25} width={25} />
          ) : (
            <div className="al-rounded-full al-h-[30px] al-w-[30px] al-bg-dfds-primary-main al-text-white al-flex al-items-center al-justify-center">
              <div className="al-dfds-label-bold">{getCredentials(email)}</div>
            </div>
          )}
        </div>
        {showMenu && urls && content && (
          <Menu
            slugs={urls}
            content={content}
            disabled={!isLoggedIn}
            onLoginClicked={onLoginClicked}
            onLogoutClicked={onLogoutClicked}
            onCreateAccountClicked={onCreateAccountClicked}
          />
        )}
      </div>
    </div>
  )
}

const getCredentials = (email: string | undefined) => {
  if (!email) return '-'
  return email.slice(0, 1).toUpperCase()
}
